<script setup lang="ts">
import '@popperjs/core';
import { ref, watch } from 'vue';
import { Popover } from 'bootstrap';
import PasswordValidator from '@/components/PasswordValidator.vue';
import { testes } from '@/scripts/ValidacaoSenha.ts';

const emit = defineEmits(['passwordChanged', 'isValidPassword']);
const senha = ref('');
const popover = ref<Popover>();
const inputRoot = ref<HTMLElement>();
const popoverContent = ref<HTMLElement>();

const hasTamanho = () => testes.testaTamanho3(senha.value);

const hasMaiusculo = () => testes.testaUpperCase(senha.value);

const hasMinusculo = () => testes.testaLowerCase(senha.value);

const hasNumero = () => testes.testaNumero(senha.value);

watch(() => senha.value, () => { emit('passwordChanged', senha.value); });

function openPopover() {
  popover.value = new Popover(inputRoot.value as Element, {
    container: 'body',
    content: popoverContent.value?.innerHTML,
    html: true,
    placement: 'bottom',
    title: 'Requisitos de senha',
    sanitize: false,
  });
  popover.value.show();
}

function closePopover() {
  // Tratativa necessária pois é possível abrir mais de um popover digitando muito rapidamente
  const popovers = Array
    .from(document.querySelectorAll('[id]'))
    // Busca todos os elementos que começam com 'popover' e terminam com um ou mais dígitos
    .filter((element) => /^popover\d+/.test(element.id));
  popovers.every((p) => p.remove());

  if (popover.value) popover.value?.dispose();
}

function updatePopover() {
  const previousPopover = popover.value;
  openPopover();
  // Tratativa para o popover não piscar na tela
  setTimeout(() => {
    previousPopover?.dispose();
  }, 200);
}

</script>
<template>
  <div class="row mb-1 m-0">
    <label class="col text-lead fw-bold p-0" for="passwordInput">Nova senha</label>
    <input
      ref="inputRoot"
      id="passwordInput"
      v-model="senha"
      @blur="closePopover"
      @keyup="updatePopover"
      @focus="openPopover"
      placeholder="Nova senha"
      minlength="12"
      maxlength="128"
      required
      type="password"
      class="form-control col"
      autocomplete="new-password">
  </div>
  <PasswordValidator :senha="senha" @isValidPassword="(v: boolean) => emit('isValidPassword', v)" />
  <div class="d-none">
    <div ref="popoverContent" id="popoverContent">
      Sua senha deve conter:
      <ul>
        <li id="sizeFeedback" :style="{ color: hasTamanho() ? 'green' : 'red' }"><strong>Pelo menos 12
          caracteres</strong></li>
        <li id="lowercaseFeedback" :style="{ color: hasMinusculo() ? 'green' : 'red' }"><strong>Pelo menos 1
          caractere minúsculo</strong>
        </li>
        <li id="uppercaseFeedback" :style="{ color: hasMaiusculo() ? 'green' : 'red' }"><strong>Pelo menos 1
          caractere maiúsculo</strong>
        </li>
        <li id="numberFeedback" :style="{ color: hasNumero() ? 'green' : 'red' }"><strong>Pelo menos 1
          número</strong></li>
      </ul>
    </div>
  </div>
</template>
