<template>
  <div class="d-flex justify-content-end align-items-center bar m-1">
    <span id="progressMessage" class="px-1 my-0 extra-small">
      {{ progressMap[validacoes].label }}
    </span>
    <div id="progressBar" class="bar w-25 bg-light rounded">
      <div :style="fillerStyles" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, watch, ref } from 'vue';
import { type StyleValue } from 'vue';
import { testes } from '@/scripts/ValidacaoSenha.ts';

const emit = defineEmits(['isValidPassword']);

const props = defineProps<{
    senha: string,
}>();

const validacoes = ref(0);

function validarSenha() {
  validacoes.value = testes.testarSenha(props.senha);
  emit('isValidPassword', validacoes.value === 6);
}

const progressMap = [
  { cor: '', label: '', preenchimento: 0 },
  { cor: '', label: '', preenchimento: 0 },
  { cor: '#c42311', label: 'Senha muito fraca', preenchimento: 20 },
  { cor: '#f1db42', label: 'Senha fraca', preenchimento: 40 },
  { cor: '#6ecf42', label: 'Quase lá', preenchimento: 60 },
  { cor: '#59981A', label: 'Senha forte', preenchimento: 80 },
  { cor: '#0072CE', label: 'Senha muito forte', preenchimento: 100 },
];

const fillerStyles = computed(() => ({
  height: '100%',
  width: `${progressMap[validacoes.value].preenchimento}%`,
  backgroundColor: progressMap[validacoes.value].cor,
  transition: 'width 1s ease-in-out',
  borderRadius: 'inherit',
} as StyleValue));

watch(() => props.senha, () => validarSenha());
</script>

<style scoped>
.bar {
  height: 8px;
}

.extra-small {
  font-size: 0.7rem;
}
</style>
