<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import RecuperarSenhaService from '@/services/RecuperarSenhaService.ts';
import PasswordField from '@/components/PasswordField.vue';
import CustomModal from '@/components/CustomModal.vue';

const recuperarSenhaService = new RecuperarSenhaService();
const route = useRoute();
const router = useRouter();

const token = ref('');
const tokenValido = ref(false);
const carregando = ref(true);
const confirmacao = ref('');
const notEquals = ref(false);
const senha = ref('');
const isValid = ref(false);
const toggleModal = ref(false);
const latitude = ref('');
const longitude = ref('');
const erro = ref(false);
const modalTitle = ref('Sua senha foi cadastrada com sucesso!');
const modalMessage = ref('Com esta senha você tem acesso aos cursos presenciais e EAD.\nAlguns serviços, como seu AVA(Ambiente Virtual de Aprendizagem), podem demorar um pouco mais para sincronizar(no máximo 2 dias) combinado');
const modalMainButtonLabel = ref('Clique aqui para efetuar o login');

function showModal() {
  toggleModal.value = !toggleModal.value;
}

function mainButtonEvent() {
  toggleModal.value = false;
  router.push({
    name: 'home',
  });
}

async function validaTokenReset() {
  recuperarSenhaService.validarToken(token.value).then((response) => {
    carregando.value = false;
    if (response.ok) {
      tokenValido.value = true;
      navigator.geolocation.getCurrentPosition((position) => {
        latitude.value = position.coords.latitude.toString();
        longitude.value = position.coords.longitude.toString();
      });
    } else {
      tokenValido.value = false;
    }
  }).catch(() => {
    tokenValido.value = false;
  });
}

async function alterarSenha() {
  try {
    const response = await recuperarSenhaService.alterarSenha(
      token.value,
      senha.value,
      latitude.value,
      longitude.value,
    );

    if (response.ok) {
      showModal();
    } else if (response.errors) {
      if (response.errors[0].status === 401) {
        tokenValido.value = false;
      } else {
        throw new Error();
      }
    } else {
      throw new Error();
    }
  } catch (error) {
    tokenValido.value = false;
    erro.value = true;
  }
}

function validaConfirmacao() {
  notEquals.value = senha.value !== confirmacao.value;
}

const checkSenha = computed(() => isValid.value);

const checkConfirmacao = computed(() => senha.value === confirmacao.value);

const btnDisabled = computed(
  () => !checkSenha.value || !checkConfirmacao.value || !confirmacao.value || !senha.value,
);

onMounted(() => {
  if (route.params.token) {
    token.value = route.params.token as string;
    validaTokenReset();
  } else {
    carregando.value = false;
    tokenValido.value = false;
  }
});
</script>
<template>
  <div class="d-flex align-items-center vh-100 bg-lightgray">
    <div class="container box-login px-4 py-4 bg-white">
      <div class="d-flex justify-content-center mb-5">
        <img src="/graph/logo.svg" class="logo" alt="Portal do Aluno">
      </div>
      <h5 class="text-primary"><strong>Redefinir senha</strong></h5>
      <div id="loadingFeedback" v-if="carregando" class="text-center mb-5">Carregando</div>
      <div id="errorFeedback" v-if="erro" class="text-center mb-5"> Ocorreu um erro no processo de alteração de senha.
        Por favor tente
        novamente.</div>
      <div id="invalidTokenFeedback" v-if="!carregando && !tokenValido && !erro" class="text-center mb-5">Por motivos
        de segurança sua
        solicitação
        expirou.
        Por favor, realize novamente o processo de redefinição de senha.</div>
      <div id="passwordFieldsBox" v-if="!carregando && tokenValido">
        <h6 class="text-center mb-5">Informe a sua nova senha de acesso ao Portal Digital do Aluno.</h6>
        <form @submit.prevent="alterarSenha">
          <div class="form-group d-flex flex-column mb-2">
            <PasswordField
              @is-valid-password="(v: boolean) => isValid = v"
              @password-changed="(s: string) => senha = s" />
          </div>
          <div class="form-group d-flex row m-0">
            <label class="col text-lead fw-bold p-0" for="passwordConfirmation">Confirmar senha</label>
            <input
              id="passwordConfirmation"
              v-model="confirmacao"
              placeholder="Confirmar senha"
              class="form-control col"
              minlength="12"
              maxlength="128"
              required
              type="password"
              @blur="validaConfirmacao"
              :disabled="!checkSenha"
              autocomplete="new-password">
          </div>

          <div
            id="passwordConfirmationMatch"
            v-if="notEquals && confirmacao"
            required
            class="d-flex justify-content-end p-0">
            <span class="small text-danger">As senhas devem ser iguais</span>
          </div>

          <button
            id="submitNewPassword"
            :class="{ 'btn-primary': !btnDisabled, 'btn-secondary': btnDisabled }"
            class="btn float-end mt-2"
            type="submit"
            :disabled="btnDisabled">
            Alterar senha
          </button>
        </form>
      </div>
    </div>
  </div>

  <CustomModal
    :title="modalTitle"
    :message="modalMessage"
    :open="toggleModal"
    :main-button-label="modalMainButtonLabel"
    :showCloseUpperIcon="false"
    @main-button-pressed="mainButtonEvent" />
</template>

<style scoped>
.logo {
    height: 8vh;
}

.box-login {
    width: 30rem;
}

.bg-lightgray {
    background-color: #eee;
}
</style>
