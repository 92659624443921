/* global RequestInit */
import { type BaseResponse } from '@/models/base/BaseResponseModel.ts';
import authStore from '@/store/authStore.ts';

/**
 * Serviço base para requisições a APIs autenticadas.
 *
 * @author Marcos Bastos
 * @class BaseService
 */
class BaseService {
  private baseURL: string;

  private store;

  constructor() {
    this.baseURL = import.meta.env.VITE_LOGIN_API_URL;
    this.store = authStore();
  }

  /**
   * Efetiva uma requisição a uma API autenticada com token Bearer JWT.
   *
   * @author Marcos Bastos
   * @date 2023-08-25
   * @param {string} method O método HTTP da requisição.
   * @param {string} endpoint O endpoint a ser requisitado.
   * @param {object|null} additionalHeaders Cabeçalhos adicionais para a requisição.
   * @param {object} payload O corpo da requisição.
   * @return {Promise<BaseResponse<T>>} O objeto de resposta da requisição.
   * @throws {Error} Caso algum dos parâmetros obrigatórios não sejam informados,
   *   ou caso ocorra algum erro ocorra na requisição.
   */
  private async request<T>(
    method: string,
    endpoint: string,
    additionalHeaders: any,
    payload: any = null,
  ): Promise<BaseResponse<T>> {
    if (!method || !endpoint) {
      throw new Error('Parâmetros base para realização da requisição inválidos.');
    }

    const requestConfiguration: RequestInit = {
      method,
      headers: {
        ...additionalHeaders,
        'Ocp-Apim-Subscription-Key': import.meta.env.VITE_SUBSCRIPTION_KEY,
        'X-XSRF-TOKEN': this.store.tokenCsrf,
      },
      body: payload ? JSON.stringify(payload) : null,
    };

    let responseData: BaseResponse<T>;
    const response = await fetch(`${this.baseURL}/${endpoint}`, requestConfiguration);

    if (response.status === 204) {
      responseData = {
        ok: true,
        data: null,
      } as BaseResponse<T>;
    } else {
      responseData = await BaseService.tryToGetResponse(response) || {};
      responseData.ok = response.ok;
    }

    responseData.response = response;

    if (!response.ok) return responseData;

    const token = response.headers.get('Authorization');

    // Salvar token na store
    if (token) {
      this.store.setToken(token.replace(/Bearer /i, ''));
    }

    return responseData;
  }

  /**
   * Realiza uma requisição do tipo GET a uma API autenticada com token Bearer JWT.
   *
   * @author Marcos Bastos
   * @date 2023-08-25
   * @param {string} endpoint O endpoint a ser requisitado.
   * @param {object|null} additionalHeaders Cabeçalhos adicionais para a requisição.
   * @return {Promise<BaseResponse<T>>} O objeto de resposta da requisição.
   */
  async get<T>(endpoint: string, additionalHeaders: any): Promise<BaseResponse<T>> {
    return this.request<T>('GET', endpoint, additionalHeaders);
  }

  /**
   * Realiza uma requisição do tipo GET a uma API autenticada com token Bearer JWT.
   *
   * @author Marcos Bastos
   * @date 2023-08-25
   * @param {string} endpoint O endpoint a ser requisitado.
   * @param {object|null} additionalHeaders Cabeçalhos adicionais para a requisição.
   * @param {object} payload O corpo da requisição.
   * @return {Promise<BaseResponse<T>>} O objeto de resposta da requisição.
   */
  async post<T>(endpoint: string, additionalHeaders: any, payload: any): Promise<BaseResponse<T>> {
    return this.request<T>('POST', endpoint, { ...additionalHeaders, 'content-type': 'application/json' }, payload);
  }

  /**
   * Tenta obter o JSON da resposta da requisição.
   *
   * @author Gabriel Anderson
   * @date 19/03/2024
   * @private
   * @param {Response} response
   * @return {Promise<object> | null} O JSON da resposta da requisição, ou `null` caso não seja
   *   possível obter o JSON.
   * @memberof BaseService
   */
  public static async tryToGetResponse(response: Response) {
    if (!response) return null;

    try {
      return await response.json();
    } catch (error) {
      return null;
    }
  }
}

export default BaseService;
