interface MarcaMidiaSocial {
  facebook: string | null;
  twitter: string | null;
  youtube: string | null;
  blog: string | null;
  instagram: string | null;
}

export interface Marca {
  _id: number;
  asset?: string;
  midiasSociais: MarcaMidiaSocial;
}

export const marcas: Marca[] = [
  {
    _id: 1,
    asset: 'anhanguera',
    midiasSociais: {
      facebook: 'https://www.facebook.com/AnhangueraEdu',
      twitter: 'https://twitter.com/Anhanguera_Edu',
      youtube: 'https://youtube.com/user/anhanguera',
      blog: 'https://blog.anhanguera.com',
      instagram: 'https://instagram.com/anhangueraedu',
    },
  },
  {
    _id: 2,
    asset: 'uniderp',
    midiasSociais: {
      facebook: 'https://www.facebook.com/anhanguerauniderpoficial',
      twitter: 'https://twitter.com/uniderpoficial',
      youtube: 'https://youtube.com/channel/UCI9lgGz92l7Vxi76FREhGEg',
      blog: 'https://blog.uniderp.com.br',
      instagram: 'https://instagram.com/uniderp_oficial',
    },
  },
  {
    _id: 3,
    asset: 'unic',
    midiasSociais: {
      facebook: 'https://www.facebook.com/universidadeunic',
      twitter: 'https://twitter.com/unic_cuiaba',
      youtube: 'https://youtube.com/universidadecuiaba',
      blog: 'https://blog.unic.com.br',
      instagram: 'https://instagram.com/universidadeunic',
    },
  },
  {
    _id: 4,
    asset: 'unime',
    midiasSociais: {
      facebook: 'https://www.facebook.com/unimefaculdade',
      twitter: 'https://twitter.com/unimefaculdade',
      youtube: 'https://youtube.com/unimefaculdade',
      blog: 'https://blog.unime.edu.br',
      instagram: 'https://instagram.com/unimeoficial',
    },
  },
  {
    _id: 5,
    asset: 'unic',
    midiasSociais: {
      facebook: 'https://www.facebook.com/universidadeunic',
      twitter: 'https://twitter.com/unic_cuiaba',
      youtube: 'https://youtube.com/universidadecuiaba',
      blog: 'https://blog.unic.com.br',
      instagram: 'https://instagram.com/universidadeunic',
    },
  },
  {
    _id: 6,
    asset: 'unopar',
    midiasSociais: {
      facebook: 'https://www.facebook.com/unoparuniversidade',
      twitter: 'https://twitter.com/UNOPAR_Oficial',
      youtube: 'https://youtube.com/unoparuniversidade',
      blog: 'https://blog.unopar.com.br',
      instagram: 'https://instagram.com/unoparoficial',
    },
  },
  {
    _id: 7,
    asset: 'pitagoras',
    midiasSociais: {
      facebook: 'https://www.facebook.com/pitagorasfaculdade',
      twitter: 'https://twitter.com/pitagorasfacul',
      youtube: 'https://youtube.com/pitagorasfaculdade',
      blog: 'https://blog.pitagoras.com.br',
      instagram: 'https://instagram.com/pitagorasfacul',
    },
  },
  {
    _id: 8,
    asset: 'unic',
    midiasSociais: {
      facebook: 'https://www.facebook.com/universidadeunic',
      twitter: 'https://twitter.com/unic_cuiaba',
      youtube: 'https://youtube.com/universidadecuiaba',
      blog: 'https://blog.unic.com.br',
      instagram: 'https://instagram.com/universidadeunic',
    },
  },
  {
    _id: 9,
    asset: 'ampli',
    midiasSociais: {
      facebook: 'https://www.facebook.com/OficialAmpli',
      twitter: 'https://twitter.com/amplioficial',
      youtube: 'https://www.youtube.com/channel/UCQuBgGO1XDvn9HmDwW9c-Hw',
      instagram: 'https://instagram.com/amplioficial',
      blog: 'https://blog.ampli.com.br/',
    },
  },
];
