<script setup lang="ts">
import { watch, ref } from 'vue';
import { Modal } from 'bootstrap';

const emit = defineEmits(['mainButtonPressed', 'secondaryButtonPressed']);

const modal = ref<Modal>();
const root = ref<any>();
const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  title: String,
  message: String,
  mainButtonLabel: String,
  secondaryButtonLabel: {
    type: String,
    default: 'Cancelar',
  },
  showSecondaryButton: {
    type: Boolean,
    default: false,
  },
  showCloseUpperIcon: {
    type: Boolean,
    default: true,
  },
});

watch(() => props.open, () => {
  if (props.open) {
    modal.value = new Modal(root.value as Element, {
      backdrop: 'static',
      keyboard: false,
    } as Partial<Modal.Options>);
    modal.value.show();
  } else if (modal.value) {
    modal.value.hide();
  }
});

</script>
<template>
  <div ref="root" class="modal fade" id="customModal" tabindex="-1" aria-labelledby="modalConfirmacao" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bg-primary text-white">
          <h5 id="modalTitle" class="modal-title">{{ props.title }}</h5>
          <button
            v-if="showCloseUpperIcon"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="fechar" />
        </div>
        <div id="modalBody" class="modal-body">
          {{ props.message }}
        </div>
        <div class="modal-footer">
          <button
            id="secondaryActionButton"
            v-if="showSecondaryButton"
            @click="emit('secondaryButtonPressed', true)"
            type="button"
            class="btn btn-secondary">{{ props.secondaryButtonLabel }}</button>
          <button
            id="mainActionButton"
            @click="emit('mainButtonPressed')"
            type="button"
            class="btn btn-primary">{{
              props.mainButtonLabel }}</button>
        </div>
      </div>
    </div>
  </div>
</template>
