import BaseService from '@/services/BaseService.ts';
import { type BaseResponse } from '@/models/base/BaseResponseModel.ts';
import type { RecuperarSenha, ResultAlterarEmail } from '@/models/RecuperarSenhaModel';

export default class RecuperarSenhaService extends BaseService {
  private endpoint: string;

  constructor() {
    super();
    this.endpoint = import.meta.env.VITE_ENDPOINT_RECUPERAR_SENHA;
  }

  public async buscarMatriculasPorCpf(cpf: string): Promise<BaseResponse<RecuperarSenha>> {
    try {
      return await this.get(`${this.endpoint}`, { 'X-PII-cpf': cpf });
    } catch (error) {
      return {
        ok: false,
        errors: [{
          title: 'Erro na para recuperar as matrículasa pelo CPF informado',
          status: 500,
          detail: error as string,
        }],
      };
    }
  }

  public async enviarEmail(
    cpf: string,
    matricula: string,
    transacao: string,
  ): Promise<BaseResponse<any>> {
    try {
      return await this.post(`${this.endpoint}/enviaEmail`, {
        'X-PII-cpf': cpf,
        'X-Transacao': transacao,
        'X-Ra': matricula,
      }, null);
    } catch (error) {
      return {
        ok: false,
        errors: [{
          title: 'Erro na requisição de envio de email',
          status: 500,
          detail: error as string,
        }],
      };
    }
  }

  public async alterarEmail(
    cpf: string,
    email: string,
    ra: string,
    transacao: string,
    dataNascimento: string,
  ): Promise<BaseResponse<ResultAlterarEmail>> {
    try {
      return await this.post(
        `${this.endpoint}/email`,
        {
          'X-PII-cpf': cpf,
          'X-Transacao': transacao,
        },
        {
          email,
          dataNascimento,
          ra,
        },
      );
    } catch (error) {
      return {
        ok: false,
        errors: [{
          title: 'Erro na requisição de alteração de email',
          status: 500,
          detail: error as string,
        }],
      };
    }
  }

  public async validarToken(token: string): Promise<BaseResponse<any>> {
    try {
      return await this.get(`${this.endpoint}/alterar?token=${token}`, null);
    } catch (error) {
      return {
        ok: false,
        errors: [{
          title: 'Erro na requisição de validação de token',
          status: 500,
          detail: error as string,
        }],
      };
    }
  }

  public async alterarSenha(
    token: string,
    senha: string,
    latitude: string,
    longitude: string,
  ): Promise<BaseResponse<any>> {
    try {
      return await this.post(
        `${this.endpoint}/alterar`,
        null,
        {
          Senha: senha,
          Token: token,
          Latitude: latitude,
          Longitude: longitude,
        },
      );
    } catch (error) {
      return {
        ok: false,
        errors: [{
          title: 'Erro na requisição de alteração de senha',
          status: 500,
          detail: error as string,
        }],
      };
    }
  }
}
