import { defineStore } from 'pinia';
import { type Marca } from '@/scripts/marcas.ts';
import { changeIcon } from '@/scripts/FaviconHelper.ts';
import type { ClientModel } from '@/models/ClientModel';

declare global {
  interface Window {
    DD_RUM?: any;
  }
}

export enum MessageType {
  SUCCESS = 'alert-success',
  ERROR = 'alert-danger',
  WARNING = 'alert-warning',
  INFO = 'alert-info',
}

interface Message {
  message: string,
  type: MessageType,
}

interface AuthStoreState {
  alunoId?: string,
  /** O primeiro nome do aluno */
  name?: string,
  token?: string,
  redirectUrl?: string,
  marca?: Marca,
  matricula?: string,
  message?: Message,
  client?: ClientModel,
  tokenCsrf?: string,
}

const authStore = defineStore('auth', {
  state: () => {
    const marca = localStorage.getItem('login:marca');
    return {
      alunoId: undefined,
      name: undefined,
      token: undefined,
      redirectUrl: undefined,
      marca: marca ? JSON.parse(marca) : undefined,
      matricula: undefined,
      message: undefined,
      client: undefined,
      tokenCsrf: undefined,
    } as AuthStoreState;
  },

  getters: {},

  actions: {
    setTokenCsrf(token: string) {
      this.tokenCsrf = token;
    },

    setToken(jwt: string) {
      this.token = jwt;
    },

    setAlunoId(id: string) {
      this.alunoId = id;
    },

    setName(name: string) {
      this.name = (([first, ...rest]) => first.toUpperCase() + rest.join('').toLowerCase())(name);
    },

    setRedirectUrl(url: string) {
      this.redirectUrl = url;
    },

    setMarca(marca: Marca) {
      this.marca = marca;
      localStorage.setItem('login:marca', JSON.stringify(marca));

      // Trocar o favicon
      changeIcon(marca);
    },

    setMessage(message: string, type: MessageType) {
      this.message = {
        message,
        type,
      };
    },

    setClient(client: ClientModel) {
      this.client = client;
    },

    selectMatricula(matricula: string) {
      this.matricula = matricula;

      // Observabilidade
      try {
        if (window.DD_RUM) {
          window.DD_RUM.setUserProperty('ra', matricula);
        }
      } catch (error) {
        console.warn('Não foi possível definir o RA para o Datadog', error);
      }
    },

    deselectMatricula() {
      this.matricula = undefined;

      // Observabilidade
      try {
        if (window.DD_RUM) {
          window.DD_RUM.removeUserProperty('ra');
        }
      } catch (error) {
        console.warn('Não foi possível remover o RA no Datadog', error);
      }
    },

    clearStore() {
      this.token = undefined;
      this.alunoId = undefined;
      this.name = undefined;
      this.redirectUrl = undefined;
      this.marca = undefined;
      this.matricula = undefined;
      this.message = undefined;
      this.client = undefined;
      this.tokenCsrf = undefined;
    },

    clearMessage() {
      this.message = undefined;
    },
  },
});

export default authStore;
