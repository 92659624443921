function testaLowerCase(value: string) {
  return /([a-z])/.test(value);
}

function testaUpperCase(value: string) {
  return /([A-Z])/.test(value);
}

function testaNumero(value: string) {
  return /([0-9])/.test(value);
}

function testaTamanho1(value: string) {
  return value.length >= 5;
}

function testaTamanho2(value: string) {
  return value.length >= 8;
}

function testaTamanho3(value: string) {
  return value.length >= 12;
}

function testarSenha(value: string) {
  const low = Number(testaLowerCase(value));
  const upper = Number(testaUpperCase(value));
  const number = Number(testaNumero(value));
  const lenght1 = Number(testaTamanho1(value));
  const lenght2 = Number(testaTamanho2(value));
  const lenght3 = Number(testaTamanho3(value));
  return low + upper + number + lenght1 + lenght2 + lenght3;
}

const testes = {
  testarSenha,
  testaLowerCase,
  testaUpperCase,
  testaNumero,
  testaTamanho1,
  testaTamanho2,
  testaTamanho3,
};

export default testes;
export { testes };
